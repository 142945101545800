

.container {
	max-width: 300px;
	margin: 100px auto 40px;
}

.container h3 {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.container li {
	padding: 10px;
	cursor: pointer;
}

/* header */
header {
	display: flex;
	padding: 40px;
	align-items: center;
}

header .title {
	flex-grow: 1;
	margin-left: 20px;
	font-size: 0.6em;
}

header h1 {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	padding-bottom: 10px;
}

header .pizza-svg {
	width: 80px;
	overflow: visible;
	stroke: #fff;
	stroke-width: 4;
	stroke-linejoin: round;
	stroke-linecap: round;
}



/* pages */
.home h2 {
	font-size: 2em;
	margin-bottom: 30px;
}

.home.container,
.order.container {
	text-align: center;
	max-width: 800px;
}

.home button {
	color: white;
	padding: 15px 30px;
	font-size: 1.6em;
	border: 3px solid white;
	margin: 30px 20px;
}

.order p {
	margin: 20px auto;
}

/* modal */
.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

.modal {
	max-width: 400px;
	margin: 0 auto;
	padding: 40px 20px;
	background: white;
	border-radius: 10px;
	text-align: center;
}

.modal button {
	color: #444;
	border-color: #444;
	font-weight: bold;
	margin-top: 20px;
}

.modal p {
	color: #444;
	font-weight: bold;
}