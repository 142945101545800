// body {
// 	min-height: 100vh;
// }

.layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	max-height: 100vh;
}

.header {
	// background-color: darkmagenta;
	height: 50px;
}

.logo {
	width: 125px;
	margin: 10px 0 0 20px;
	position: absolute;
}

.spacer {
	height: 5vw;
}
.main{
	// background-color: indigo;
	flex-grow: 1;
	// margin: 10px;
	// align-items: stretch;
	display: flex;
	flex-direction: column;
	hr {
		background-color:#03004f;
		// width: 80%;
		margin: 0 3%;
	}
}

.hero-wrapper {
	display: flex;
	flex-direction: row;
	// align-items: flex-end;
	justify-content: center;
}

// .hero {
	// border: 1px solid orange;
	// display: flex;
	// margin-left: 50px;
	// width: 100%;
	// flex: 1;
// }

// .hero-title {
	// font-size: 5rem;
	// color: #ff9900;
	// text-align: right;
// }

.hero-subtitle {
	// font-size: 2rem;
	color: #b36b00;
	// text-align: right;
}

.hero-banner {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hero-banner-bar {
	// background: linear-gradient(90deg, rgba(0,0,90,1) 0%, rgba(0,0,0,1) 80%);
	margin: 20px;
	min-height: 20px;
}

.hero-banner-bar.top{
	background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,128,1) 5%, rgba(0,0,0,1) 100%);
}

.hero-banner-bar.middle{
	background: linear-gradient(90deg, rgba(0,90,90,1) 0%, rgba(0,0,0,1) 80%);
}

.hero-banner-bar.bottom{
	background: linear-gradient(90deg, rgba(99,90,90,1) 0%, rgba(0,0,0,1) 80%);
}

.home-img {
	// max-height: 500px;
	flex: 1;
	display: flex;
	// align-items: center;
	img {
		object-fit: contain;
		width: 100%;
		max-width: 1000px;
		margin: auto;
	}
}

.svg-logo {
	width: 250px;
	overflow: visible;
	stroke: rgb(127, 0, 223);
	// stroke-width: 4;
	// stroke-linejoin: round;
	// stroke-linecap: round;
}

.clone-example {
	// background-color: aqua;
	height: 700px;
	// border: 3px solid blue;
}

.navbar-custom-item {

	font-size: 22px;
	// color: #0000ff;
	display: block;
	line-height: 51px;
	height: 52px;
	width: 120px;
	position: relative;
	text-decoration: none;
	// border: 1px solid green;
	text-align: center;

	svg {
		transition: all 0.3s ease-out;
	}
	
	&:hover {
		color: #ff9900;
		
		svg {
			opacity: 0;
		}
		
		&:after {
			opacity: 1;
		}
	}

	&:after {
		content: '';
		font-size: 15px;
		letter-spacing: 2px;
		position: absolute;
		bottom: 0;
		display: block;
		width: 100%;
		text-align: center;
		opacity: 0;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}

	&:first-child {
		&:after {
			content: 'HOME';
		}
	}
}
a.dev-link {
	&:after {
		content: 'DEV';
	}
}
a.three-d-link {
	&:after {
		content: '3D';
	}
}
a.about-link {
	&:after {
		content: 'ABOUT';
	}
}

a.contact-link {
	&:after {
		content: 'CONTACT';
	}
}

.my-footer {
	background-color: aqua;
}

.contact-form-wrapper {
	background-color: #00002e;
	margin-top: 100px;
	padding: 20px;
	border: 1px solid rgba(255, 166, 0, 0.265);
	border-radius: 5px;
}

.input, .textarea {
	background-color: #c9c9c9;
}


.label {
	color: rgb(210, 210, 210);
}

.error404 {
	flex-grow: 1;
	.text-header {
		font-size: 100px;
		text-align: center;
		margin-top: 30%;
		color: yellow;
	}
	.text-subtitle {
		font-size: 30px;
		text-align: center;
		color: yellow;
	}
}

// .wrapper {
// 	display: flex;
// 	flex-direction: column;
// 	min-height: 100vh;
// }

// .wrapper>* {
// 	padding: 20px;
// }

// .page-main {
// 	flex-grow: 1;
// }
