@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

// Set your brand colors
$orange: #ff9900;
$pink: #e319d2;
$blue: #0282e3;
$green: #4ce30e;

// adobe square color pallett
// ff9900
// 4ce30e
// 0282e3
// e319d2

// Update Bulma's global variables
$family-sans-serif: "Raleway", sans-serif;
//$grey-dark: #ff9900;
//$grey-light: rgb(121, 37, 37);
$primary: $orange;
$link: $blue;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: black;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$navbar-background-color: black;
$input-color: $link;
$navbar-breakpoint: 1px;
$size-1: 5rem;
$size-2: 4.5rem;
$size-3: 4rem;
$size-4: 3.5rem;
$size-5: 3.25rem;
$size-6: 1rem;
$size-7: 0.5rem;

@import "../../node_modules/bulma/bulma.sass";
// Import only what you need from Bulma
// @import "../../node_modules/bulma/sass/utilities/_all.sass";
// @import "../../node_modules/bulma/sass/base/_all.sass";
// @import "../../node_modules/bulma/sass/elements/button.sass";
// @import "../../node_modules/bulma/sass/elements/container.sass";
// @import "../../node_modules/bulma/sass/elements/title.sass";
// @import "../../node_modules/bulma/sass/form/_all.sass";
// @import "../../node_modules/bulma/sass/components/navbar.sass";
// @import "../../node_modules/bulma/sass/layout/hero.sass";
// @import "../../node_modules/bulma/sass/layout/section.sass";